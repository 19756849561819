.Hero {
	background-image: url('/web/img/hero.jpg');
	background-size: cover;
	padding-top: 19px;

	&-scroll {
		position: relative;
		height: 20px;
		width: 100%;
		background-image: url('/web/img/hero-scroll.png');
		background-repeat: no-repeat;
		background-position: bottom center;
		margin-top: 70px;

		&:before {
			content: "Learn more";
			position: absolute;
			left: 50%;
			top: -32px;
			color: @color--white;
			font-size: @fontSize--small;
			transform: translateX(-50%);
		}
	}
}

@media(min-width: @screen-sm-min) {
	.Hero-scroll {
		margin-top: 134px;
	}

	.Hero {
		margin-bottom: 20px;
	}
}
