.Heading {
	margin: 0;
}

.Heading--primary {
	color: @colorPrimary;
}

.Heading--secondary {
	color: @color--black;
}

.Heading--large {
	font-size: @fontSize--xlarge;
	line-height: 36px;
	margin-bottom: 45px;
}

.Heading--medium {
	font-size: @fontSizeHeadline--small;
}

.Heading--main {
	margin: 30px 0;
	color: @color--white;
}

.Heading--center {
	text-align: center;
}

.Heading--subpage {
	margin: 45px 0 15px;
}

@media(min-width: @screen-sm-min) {
	.Heading--main {
		margin: 50px 0;
	}

	.Heading--large {
		font-size: @fontSizeHeadline--large;
	}

	.Heading--medium {
		font-size: @fontSizeHeadline;
	}
}