.QuoteBox {
	position: relative;
	padding: 30px 35px;
	margin-top: 20px;
	background-color: @color--white;
	border: 1px solid @colorBorders--light;
	border-radius: 10px;

	&-left {
		width: 100%;
		text-align: center;
		margin-bottom: 15px;
	}

	&-right {
		padding: 7px 23px;
		background-image: url('/web/img/quote.png');
		background-repeat: no-repeat;
		background-position: top left;
	}

	&-title {
		text-transform: uppercase;
		font-size: @fontSize--small;
		color: @colorText--light;
		margin-top: 14px;
	}

	&:before {
		content: "";
		position: absolute;
		width: 45px;
		height: 23px;
		background-image: url('/web/img/triangle.png');
		display: none;
	}
}

.QuoteBox--topLeft {

	margin-top: 0;

	&:before {
		right: 30px;
		top: -23px;
	}
}

.QuoteBox--topRight {

	&:before {
		left: 30px;
		top: -23px;
	}
}

.QuoteBox--bottomLeft {

	&:before {
		right: 30px;
		bottom: -23px;
		transform: rotate(180deg);
	}
}

.QuoteBox--bottomRight {

	&:before {
		left: 30px;
		bottom: -23px;
		transform: rotate(180deg);
	}
}

@media(min-width: @screen-sm-min) {
	.QuoteBox {
		height: 182px;
	}

	.QuoteBox-left {
		margin-right: 12px;
		margin-bottom: 0;
		width: auto;
		float: left;
	}

	.QuoteBox-right {
		margin-left: 192px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}

	.QuoteBox--topLeft {
		margin-top: 20px;
	}
}

@media(min-width: @screen-lg-min) {
	.QuoteBox--topLeft,
	.QuoteBox--topRight,
	.QuoteBox--bottomLeft,
	.QuoteBox--bottomRight {

		&:before {
			display: block;
		}
	}
}