.Btn {
	display: inline-block;
	font-size: @fontSize--medium;
	border-radius: 100px;
	padding: 12px 35px 11px;
	transition: all ease-in-out 0.4s;

	&:focus {
		outline: none;
	}
}

.Btn--primary {
	color: @color--white;
	background-color: @colorPrimary;
	border: 1px solid @colorPrimary;

	&:hover,
	&:focus {
		color: @color--white;
		text-decoration: none;
	}

	&:hover {
		background-color: darken(@colorPrimary, 10%);
		border: 1px solid darken(@colorPrimary, 10%);
	}
}

.Btn--secondary {
	color: @color--white;
	background-color: @colorPrimary;
	border: 1px solid @colorPrimary;
	font-size: @fontSize--smaller !important;
	padding: 8px 15px 7px;

	&:hover,
	&:focus {
		color: @color--white;
		text-decoration: none;
	}

	&:hover {
		background-color: darken(@colorPrimary, 10%);
		border: 1px solid darken(@colorPrimary, 10%);
	}
}

.Btn--arrow {
	height: 8px;
	width: 20px;
	position: absolute;
	padding: 0;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);

	&:after {
		content: "";
		height: 8px;
		width: 20px;
		background-image: url('/web/img/arrow.png');
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		transition: all ease-in-out 0.4s;
	}

	&:hover {

		&:after {
			bottom: -3px;
		}
	}
}

.Btn--small {
	font-size: @fontSize;
	padding: 5px 18px;
}

.Btn--left {
	margin-right: 20px;
}

.Btn--right {
	margin-left: 20px;
}

.Btn--bottom {
	margin-top: 45px;
}

.Btn--form {
	margin-top: 35px;
}

@media(min-width: @screen-sm-min) {
	.Btn {
		font-size: @fontSize--large;
	}

	.Btn--small {
		font-size: @fontSize;
	}
}
