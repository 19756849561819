.Section {
	padding: 30px 0;
}

.Section--full {
	padding: 0;
}

.Section--center {
	text-align: center;
}

.Section--bg {
	background-color: @colorBg;
}

@media(min-width: @screen-sm-min) {
	.Section {
		padding: 60px 0;
	}

	.Section--full {
		padding: 0;
	}
}