.Text {
	color: @color--black;
}

.Text--inversed {
	color: @color--white;
}

.Text--primary {
	color: @colorPrimary;
}

.Text--large {
	font-size: @fontSize--large;
}