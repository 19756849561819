.Paragraph {
	margin: 0;
}

.Paragraph--spacingTop {
	margin-top: 45px;
}

.Paragraph--spacingBottom {
	margin-bottom: 45px;
}