.Image {
	
}

.Image--bottom {
	margin: 0 auto -30px;
}

@media(min-width: @screen-sm-min) {
	.Image--bottom {
		margin: 0 auto -60px;
	}
}