.Input {
	border: 1px solid @colorBorders--dark;
	border-radius: 5px;
	color: @colorText;
	height: auto;
	padding: 14px 25px;
	resize: none;

	&:focus {
		border: 1px solid @colorBorders--dark;
		box-shadow: 0px 0px 0px 4px @colorBorders--light;
		outline: none;
	}

	&[type='number'] {
		-moz-appearance:textfield;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
	    -webkit-appearance: none;
	}

	&.error {
		border: 1px solid @colorPrimary;

		&+label {

			&.error {
				color: @colorPrimary;
				font-size: @fontSize--small;
				text-align: left;
			}
		}
	}
}

@media(min-width: @screen-sm-min) {
	.Input {
		min-width: 360px;
	}
}