.Link {
	color: @colorSecondary;
	line-height: 24px;

	&:hover {
		color: @colorSecondary;
		text-decoration: underline;
	}
}

.Link--footer {
	color: @colorText--light;
	text-decoration: underline;

	&:hover {
		color: @colorText--light;
		text-decoration: none;
	}

	&+.Link {
		margin-left: 30px;
	}
}