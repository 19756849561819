.Footer {
	padding: 15px 0;
	background-color: @color--white;
	border-top: 1px solid @colorBorders--light;

	&-item {
		text-align: center;
		color: @colorText--light;
		font-size: @fontSize--small;
		line-height: 24px;
	}
}

@media(min-width: @screen-sm-min) {

	.Footer-item {

		&:first-child {
			text-align: left;
		}

		&:last-child {
			text-align: right;
		}
	}
}