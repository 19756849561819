.Icon {
	margin: 70px auto 23px;

	&-section {
		margin-bottom: 64px;
	}
}

@media(min-width: @screen-sm-min) {
	.Icon {

		&-section {
			padding: 0 30px;
		}
	}
}